@import 'src/styles/theme';

.wrapper {
	display: flex;
	gap: 32px;
	justify-content: flex-start;

	.listWrapper,
	.donutWrapper {
		width: 50%;
		border: 1px solid #e4e7ec;
		box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
		border-radius: 8px;
		position: relative;

		&.flex {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.labelWrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 24px 0 24px 20px;
			gap: 4px;

			.label {
				font-weight: 600;
				font-size: 18px;
				line-height: 28px;
			}
		}

		.headers {
			display: flex;
			justify-content: space-between;
			padding: 13px 61px 13px 24px;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: #475467;
			border-top: 1px solid #eaecf0;
			position: relative;

			.loadingWrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
			}
		}

		.list {
			.listItem {
				border-top: 1px solid #eaecf0;
				padding: 14px 61px 14px 24px;
				display: flex;
				justify-content: space-between;
				max-height: 73px;

				.itemName {
					font-weight: 500;
					font-size: 14px;
					line-height: 20px;
					min-width: 180px;

					.advertiser {
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: $gray500;
					}
				}

				.itemValue {
					min-width: 30px;
					font-weight: 400;
					font-size: 14px;
					line-height: 42px;
					color: #475467;
				}
			}
		}

		.error {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 11px 12px;
			background-color: #fda29b;
			color: white;
			border-radius: 8px;
			width: 261px;
		}

		.noResults {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 11px 12px;
			background-color: #adaeff;
			color: white;
			border-radius: 8px;
			width: 261px;	
			text-align: center;
		}
	}
}
