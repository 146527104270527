@import 'src/styles/theme.scss';

.inputWrapper {
	width: 100%;
	max-width: 400px;
}

.noConversion {
	font-size: 14px;
	font-weight: 500;

	.link {
		color: $primaryColor;
		border-bottom: 1px dotted $primaryColor;
	}
}

.addChannelcaps {
	align-self: center;
	width: 100%;

	.button {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		border-bottom: 1px dotted $primaryColor;
		width: 153px;
		color: $primary700;

		&.disabledBtn {
			color: $gray300;
			border-bottom: 1px dotted $gray300;
		}
	}
}

.defaultPointer {
	cursor: default;
}

.sectionTitle {
	text-decoration: underline;
}

.tableOfContentsWrapper {
	height: 100%;
	border-right: 1px solid #eaecf0;
}

.stickToTop {
	align-self: flex-start;
}

.macrosWrapper {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	gap: 8px;
	flex-wrap: wrap;

	.macroItem {
		display: flex;
		background-color: $blue50;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 4px;
		padding: 4px 12px 4px 10px;
		height: 28px;
		border-radius: 16px;
		cursor: pointer;
		transition: linear 200ms;

		.icon {
			width: 14px;
			font-size: 14px;
			color: $blue500;
		}

		.text {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: $blue700;
		}

		&:hover {
			background-color: $blue100;
			color: $blue100;

			.icon {
				width: 14px;
				font-size: 14px;
				color: $blue600;
			}

			.text {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				color: $blue800;
			}
		}

		&.active {
			background-color: $success50;

			.icon {
				color: $success500;
			}

			.text {
				color: $success700;
			}

			&:hover {
				background-color: $success100;

				.icon {
					color: $success600;
				}

				.text {
					color: $success800;
				}
			}
		}
	}
}

.collapsed {
	overflow: hidden;
	transition: height 1s;
}


//Sider slider stuff
.titleSs {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}

.wrapperSs {
	padding: 21px 24px;
	top: 0;
	position: absolute;
}

.advertiserBottomBarWrapperSs {
	gap: 12px;
	padding: 0;
	position: relative;
}

.advertiserBottomBarSs {
	border: none !important;
	border-top: 1px solid #eaecf0 !important;
	padding: 0 !important;
}

.channelsDropdown {
	width: 320px;
}

.checkbox {
	&[type=checkbox] {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		outline: none;
		content: none;
		cursor: pointer;
	}

	&[type=checkbox]:before {
		font-family: "outlined-icons";
		content: "\f1e7";
		color: transparent !important;
		background: #fff;
		display: block;
		width: 15px;
		height: 15px;
		border: 1px solid #D0D5DD;
		border-radius: 6px;
	}

	&[type=checkbox]:hover:before {
		border: 1px solid #7F56D9;
	}

	&[type=checkbox]:checked:before {
		font-size: 15px;
		color: #7F56D9 !important;
		background: #F9F5FF;
		border: 1px solid #7F56D9;
	}

	&[type=checkbox]:checked:hover:before {
		border: 1px solid #5b22d6;
	}
}