@import 'src/styles/theme.scss';

.errorTooltip {
	width: 343px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 5px;

	.errorTooltipTitle {
		display: flex;
		align-items: center;
		gap: 4px;
		color: $gray700;
		margin-bottom: 4px;
		font-size: 14px;
	}
	.errorCard {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: left;
		gap: 8px;
		padding: 8px;
		border: $gray200 solid 1px;
		max-height: 150px;
		color: $gray500;
		font-weight: 400;

		.errorDescWrapper {
			display: flex;
			gap: 8px;
			min-width: 55px;

			// .errorCardIndex {
			// 	color: $gray700;
			// 	font-size: 14px;
			// 	width: 47px;
			// }

			.errorCardErrorKey {
				min-width: 47px;
				// uncomment incase we want the error to be in the middle
				// display: flex;
				// align-self: center;
			}

			.errorCardErrorValue {
				color: $gray600;
				font-weight: 500;
			}
		}

		.columnNameWrapper {
			display: flex;
			gap: 8px;

			.errorCardColumnKey {
			}

			.errorCardColumnValue {
				color: $gray600;
				font-weight: 500;
			}
		}
	}
}

.errorColumnWrapper {
	display: flex;
	align-items: center;
	gap: 4px;

	.errorsCount {
		color: $error500;
		font-size: 14px;
	}
}

.reviewStatus {
	font-size: 16px;
	&.error {
		color: $error500;
	}
	&.success {
		color: $success500;
	}
}
