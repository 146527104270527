@import 'src/styles/theme';

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}

.errorMsg {
	min-width: 100px;
}

.wrapper {
	padding: 21px 24px;
	top: 0;
	position: absolute;
}

.advertiserBottomBarWrapper {
	gap: 12px;
	padding: 0;
	position: relative;
}

.advertiserBottomBar {
	border: none !important;
	border-top: 1px solid #eaecf0 !important;
	padding: 0 !important;
}
