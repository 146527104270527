@import 'src/styles/theme';

.main {
	background-color: $backgroundColor;
	min-height: 100vh;
	&.sidebar {
		.minimize {
			transition: 0.15s;
			margin-left: 260px;
		}
		.maximize {
			transition: 0.15s;
			margin-left: 60px;
		}
	}
}
