@import 'src/styles/theme';

.mainWrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	max-width: 350px;
	position: relative;
	margin-top: 15%;

	.title {
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 15px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: $gray600;
		margin-bottom: 25px;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.qrWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.formWrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;

		.error {
			justify-content: flex-start !important;
			margin-top: -25px;
			font-size: 13px;
			padding-left: 5px;
		}
	}
}
