$bottomBarFromLeft: var(--bottomBarFromLeft, 0px); 
$bottomBarFromRight: var(--bottomBarFromRight, 0px); 
$bottomBarFromBottom: var(--bottomBarFromBottom, 0px); 

$bottomBarHeight: var(--bottomBarHeight, 75px); 
$bottomBarBackgroundColor: var(--bottomBarBackgroundColor, white); 
$bottomBarBoxShadow: var(--bottomBarBoxShadow, 0 0 2rem 0 rgb(136 152 170 / 15%)); 
$bottomBarPadding: var(--bottomBarPadding, 0 20px); 
$bottomBarGap: var(--bottomBarGap, 10px); 
$bottomBarBorderBottom: var(--bottomBarBorderBottom, none); 
$bottomBarBorderRight: var(--bottomBarBorderRight, none); 
$bottomBarBorderLeft: var(--bottomBarBorderLeft, none); 
$bottomBarBorderTop: var(--bottomBarBorderTop, none); 
$bottomBarPosition: var(--bottomBarPosition, fixed);
$bottomBarBorderTop: var(--bottomBarBorderTop, 1px solid #EAECF0);