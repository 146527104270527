// TODO - default
.wrapper {
	border-bottom: 1px solid #e7eaf0;
	width: 100%;
	display: flex;
	padding: 0 0 0 30px;
	gap: 40px;

	.tabItem {
		border-bottom: 1px solid transparent;
		padding: 0 0 14px 0;
		color: #667085;

		&.active {
			border-bottom: 2px solid #6941c6;
			color: #6941c6;
			font-weight: 500;
		}
	}
}
