.prevNextWrapper {
	display: flex;
	position: fixed;
	gap: 15px;
	right: 175px;
	z-index: 8;

	.indexOfSum {
		font-size: 13px;
		color: #636363;
		white-space: nowrap;
		line-height: 50px;
	}

	.btn {
		height: 50px;

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
