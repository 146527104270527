@import 'src/styles/theme';

.wrapper {
	background-color: #f9fbff;
	padding: 32px;

	.downloadAndUpload {
		display: flex;
		justify-content: flex-start;
		align-content: stretch;
		gap: 24px;

		.downloadCard {
			padding: 84px 24px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			height: 350px;
			justify-content: flex-end;
			width: 50%;

			.title {
				font-weight: 500;
				font-size: 20px;
				line-height: 20px;
				color: $gray700;
			}

			.bullets {
				display: flex;
				flex-direction: column;
				gap: 8px;
				color: $gray700;

				.bullet {
					display: flex;
					gap: 6px;
				}
			}

			.downloadBtns {
				display: flex;
				gap: 21px;
				padding-top: 5px;

				.btn {
					width: 164px;
				}
			}
		}

		.uploadCard {
			padding: 25px 24px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			justify-content: flex-end;
			height: 350px;
			width: 50%;

			.title {
				font-weight: 400;
				font-size: 16px;
				color: $gray600;
			}

			.toggleAndUpload {
				display: flex;
				gap: 20px;

				.gBtn {
					width: 194px;
					height: 122px;
				}

				.dragAndDrop {
					@media (min-width: 1300) {
						width: 471px;
					}
					height: 261px;
					border: 1px solid $gray300;
					border-radius: 12px;
					display: flex;
					flex-direction: column;
					gap: 10px;
					align-items: center;
					justify-content: center;
					position: relative;

					.icon {
						background-color: #f5f3ff;
						color: $primaryColor;
						height: 40px;
						width: 40px;
						display: flex;
						justify-content: center;
						border-radius: 28px;
						align-self: center;
					}

					.textWrapper {
						display: flex;
						flex-direction: column;

						.actionText {
							color: $gray600;
							font-size: 14px;
							font-weight: 400;
							line-height: 20px;
							display: flex;

							.highlightText {
								color: $primaryColor;
								font-weight: 600;
								cursor: pointer;
							}
						}

						.description {
							font-size: 12px;
							font-weight: 400;
							text-align: center;
							line-height: 18px;
						}
					}

					.errorUpload {
						display: flex;
						background-color: $error50;
						width: 95%;
						padding: 10px 0px 10px 16px;
						border-radius: 12px;
						gap: 15px;
						position: absolute;
						bottom: 10px;

						.errorIcon {
							color: #d64c1d;
							background-color: #ffeae3;
							border-radius: 20px;
							width: 32px;
							height: 32px;
							text-align: center;
							// font-size: 20px;

							&::before {
								position: relative;
								left: 8px;
								font-size: 18px;
							}
						}

						.errorUploadMessage {
							font-size: 14px;
							font-weight: 600;
							color: $error500;
						}

						.errorFileName {
							font-size: 14px;
							font-weight: 400;
							color: $error500;
						}
					}
				}
			}
		}
	}

	.reviewTitle {
		font-size: 20px;
		margin: 26px 0 14px 0;
	}

	.tableWrapper {
		background: white;
		margin-top: 5px;
		padding: 24px;
		border: 1px solid #eaecf0;
		border-radius: 15px;

		.tableTopSection {
			display: flex;
			justify-content: space-between;

			.left {
				display: flex;
				flex-direction: column;

				.filename {
					color: $gray700;
					font-size: 16px;
				}

				div:nth-child(2) {
					margin: 16px 0;
					color: $gray700;
					font-size: 14px;
					font-weight: 400;

					span {
						color: $gray500;
					}
				}
			}

			.right {
				.downloadFileBtn {
					&.active {
						border-color: #5651f3;
						color: #5651f3;
					}
				}
			}
		}
	}

	.table {
		padding: 0;
	}

	.publishWrapper {
		margin-top: 20px;
		margin-left: auto;
		display: flex;
		align-items: center;
		gap: 20px;

		.hint {
			display: flex;
			gap: 5px;
			font-size: 14px;
			font-weight: 400;
			color: $gray600;
		}

		.publishBtn {
			width: 129px;
		}
	}
}
