@import 'src/styles/theme';
@import '@monorepo/base/src/styles/text/overflow';

.wrapper {
	.errorsSummary {
		color: $error500;
		font-size: 12px;
		font-weight: 500;
		margin: 3px 0;
	}
	.publishSuccess {
		color: $success500;
		font-size: 12px;
		font-weight: 500;
		margin: 3px 0;
	}

	.fileNameWrapper {
		display: flex;
		gap: 6px;

		.successIcon {
			color: $success500;
		}

		.errorIcon {
			color: $error500;
		}
		.fileName {
			font-size: 14px;
			color: $gray500;
			font-weight: 500;
            @include text-overflow;
            max-width: 215px;
		}
		.strategyType {
			font-size: 14px;
			color: $gray500;
			position: absolute;
			right: 20px;
			margin-top: -15px;
		}
	}

	.progressWrapper {
		display: flex;
		align-items: center;

		.container {
			height: 22px;
			width: 100%;
			// max-width: 225px;
			background: #bebebeb9;
			border-radius: 50px;
			margin: 10px 0 10px 0;
			position: relative;

			.percentage {
				position: absolute;
				text-align: center;
				left: 50%;
				transform: translate(-50%, 0);
				color: white;
				font-size: 14px;
				line-height: 22px;
				font-weight: 400;
			}

			.filter {
				height: 100%;
				border-radius: inherit;
				transition: 200ms ease-in-out;
			}
		}
	}
}
