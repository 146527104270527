@import '@monorepo/base/src/styles/devices';
@import '@monorepo/base/src/styles/scrollbar';
@import './font';

// manifesto specific
$blue50: #eff8ff;
$blue100: #d1e9ff;
$blue500: #2e90fa;
$blue600: #1570ef;
$blue700: #175cd3;
$blue800: #1849a9;
$success50: #ecfdf3;
$success100: #d1fadf;
$success500: #12b76a;
$success600: #039855;
$success700: #027a48;
$success800: #05603a;
$error25: #fffbfa;
$error50: #fef3f2;
$error100: #fee4e2;
$error300: #fda29b;
$error400: #f97066;
$error500: #f04438;
$error600: #d92d20;
$error700: #b42318;
$error800: #912018;
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning400: #fdb022;
$warning500: #f79009;
$warning600: #dc6803;
$warning700: #b54708;
$warning800: #93370d;
$gray50: #f9fafb;
$gray100: #f2f4f7;
$gray200: #eaecf0;
$gray300: #d0d5dd;
$gray400: #98a2b3;
$gray500: #667085;
$gray600: #475467;
$gray700: #344054;
$gray900: #101828;
$grayNeutral50: #f9fafb;
$grayNeutral100: #f3f4f6;
$grayNeutral400: #9da4ae;
$primary700: #6941c6;
$primary600: #7f56d9;
$primary100: #f4ebff;
$primary50: #f9f5ff;
$gray900: #101828;
$hoverGrayColor: $grayNeutral100;
//

$primaryColor: #6941c6;
$primaryColorLight: #e2e2ff80;
$lightBlueBg: #f0f9ff;
$primaryColorDarken: #423ff0;
$secondaryColor: #6b6b6b;
$backgroundColor: #ffffff;
$titleColor: $gray900;
$subTitleColor: #475467;
$hoveredTitleColor: #1d1d1d;
$red: #fb6340;
$dangerColor: #dc3545;
$dangerColorHover: rgb(187, 45, 59);
$hoverLinksBg: #bcbcbc28;

//form
$labelColor: #7c7c7c;
$borderElementsColor: #d0d5dd;
$maxWidthElements: 400px;
$errorColor: #fb6340;
$errorColorLight: #fa7557;
$successColor: #2dce89;
$successColorLight: #3bd994;
$whiteColor: #ffffff;
$formFocusBorder: 1px solid #d6bbfb;
$formFocusBoxShadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;

$disabledBackgroundColor: #acacac;
$disabledColor: #000000;

$actionLinerIconTextColor: #6b6b6b;
$actionLinerIconColor: $primary600;
