@import './sidebar.default.scss';

.wrapper {
	padding: $controlledSidebarWrapperPadding;
	display: flex;
	flex-direction: column;

	.logoWrapper {
		height: $controlledSidebarTogglerHeight;
		padding: $controlledSidebarTogglerPadding;
		display: flex;
		justify-content: space-between;
	}

	.fullList {
		.itemWrapper {
			.itemTitle {
				margin: $controlledSidebarFullListItemTitleMargin;
				white-space: nowrap;
			}
		}
	}

	.hamburgerSidebar {
		display: flex;
		align-self: center;
		color: #6b6b6b;

		&.openSidebar {
			align-self: flex-end;
		}
	}

	.list {
		margin: $controlledListMargin;

		.itemWrapper {
			width: $controlledListItemWrapperWidth;
			height: $controlledListItemWrapperHeight;
			line-height: $controlledListItemWrapperLineHeight;
			font-size: $controlledListItemWrapperFontSize;
			font-weight: 500;
			padding: $controlledListItemWrapperPadding;
			cursor: pointer;
			margin: $controlledListItemWrapperMargin;
			display: flex;
			align-items: center;
			// border-radius: 0px;

			&:hover {
				background-color: $controlledListItemWrapperHoverBackgroundColor;
			}

			&.activeItem {
				background-color: $controlledListItemWrapperActiveItemBackgroundColor;
				// border-radius: 0px;

				&:hover {
					background-color: $controlledListItemWrapperActiveItemHoverBackgroundColor;
				}

				.itemLink {
					color: $primary700;
				}
			}

			.itemLink {
				height: 100%;
				width: 100%;

				.item {
					display: flex;
					align-items: center;
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.miniLogoWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	.miniLogo {
		position: relative;
		width: 50px;
		height: 18px;
		object-fit: contain;
	}
}
