@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.refWrapper {
	width: 100%;
	position: relative;
	.iconWrapper {
		position: relative;
		left: 46%;
		top: 40%;

		.loader {
			transform-origin: center;
			animation: rotate 1s ease-in-out infinite;
			width: 40px;
			transform: translate(-50%, -50%);
		}
		.loaderText {
			position: relative;
			margin-left: -53px;
            font-size: 12px;
		}
	}
}
