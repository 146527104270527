@import './toggle.default.scss';

.wrapper {
	.title {
		display: block;
		font-size: $toggleTitleFontSize;
		margin: $toggleTitleMargin;
	}

	.switchWrapper {
		display: flex;
		align-items: center;

		.switch {
			position: relative;
			display: inline-block;
			width: $toggleSwitchWidth;
			height: $toggleSwitchHeight;
			margin: $toggleSwitchMargin;

			input {
				opacity: 0;
				width: 0;
				height: 0;

				&:checked {
					+ {
						.slider {
							background-color: $toggleSliderActiveBackgroundColor;

							&:hover {
								background-color: $toggleSliderActiveHoverBackgroundColor;
							}
							&:before {
								transform: translateX(16px);
							}
						}
					}
				}

				&:focus {
					+ {
						.slider {
							box-shadow: $toggleSliderFocusBoxShadow;
						}
					}
				}
			}

			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $toggleSliderDefaultBackgroundColor;
				transition: all 0.2s ease-in-out;
				border-radius: $toggleSliderBorderRadius;

				&:before {
					border-radius: 50%;
					position: absolute;
					content: '';
					height: calc(13px * $toggleRelativeSize);
					width: calc(13px * $toggleRelativeSize);
					left: calc(2px * $toggleRelativeSize);
					bottom: calc(2px * $toggleRelativeSize);
					background-color: white;
					transition: all 0.2s ease-in-out;
				}

				&:hover {
					background-color: $toggleSliderDefaultHoverBackgroundColor;
				}
			}
		}

		.label {
			color: $toggleLabelColor;
			font-size: $toggleLabelFontSize;
			white-space: pre;
		}
	}
}
