@import 'src/styles/theme.scss';

.active {
	font-size: 20px;
	position: relative;
	left: -2px;
}

.paused {
	font-size: 15px;
}

.draft {
	font-size: 15px;
}

.deleted {
	font-size: 14px;
}

.countries {
	display: flex;
	align-items: center;
	gap: 7px;

	img {
		width: 18px;
		position: relative;
		top: -1px;
	}

	span {
		line-height: 18px;
	}
}

.centeredCell {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.landingPageCell {
	color: #6366f1;
}

.bulkCampaignsActionsMenu {
	max-width: 215px !important;
}

.downloadDropdown {
	display: flex;
	align-items: center;
	width: 200px;
	gap: 5px;

	.filename {
		display: flex;
		gap: 6px;

		span {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}
	}
}
