@import '../theme.scss';

:root {
	--navRelativeWrapperNavPadding: 0px 10px 0px 0px;
	--navRelativeWrapperNavMargin: 100px 0px 0px 0px;
	--navItemHoverBackgroundColor: #{$grayNeutral100};
	--navWidth: 300px;
	--navMinWidth: 300px;
	--navMaxWidth: 300px;
	--navRelativeWrapper: 380px;

	--navPageMaxWidth: calc(100% - var(--navWidth));
}
