@import '@monorepo/base/src/styles/resets/button';
@import './g-button.default.scss';

.button {
	@include resetBtn;
	width: 100%;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	flex-direction: $gButtonFlexDirection;
	transition: all 0.2s ease-in-out;
	max-width: $gButtonMaxWidth;
	height: $gButtonHeight;
	background-color: $gButtonBackgroundColor;
	color: $gButtonColor;
	font-size: $gButtonFontSize;
	border-radius: $gButtonBorderRadius;
	margin: $gButtonMargin;
	border: $gButtonBorder;
	position: relative;

	&:last-child {
		margin-right: 0px !important;
	}

	&:hover {
		background-color: $gButtonHoverBackgroundColor;
		color: $gButtonHoverColor;
	}

	&.active {
		background-color: $gButtonActiveBackgroundColor;
		color: $gButtonActiveColor;
		border: $gButtonActiveBorder;

		&:hover {
			border: $gButtonActiveBorder;
		}
		.icon {
			color: $gButtonIconActiveColor;
		}
	}

	&.disabled {
		border: $gButtonDisabledBorder;
		cursor: not-allowed;
	}

	&.error {
		border: $gButtonErrorBorder;
	}

	.icon {
		font-size: $gButtonIconSize;
		color: $gButtonIconColor;
	}

	.text {
		margin: $gButtonTextMargin;
		color: $gButtonTextColor;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
}
