@import 'src/styles/theme';

.chartWrapper {
	border-top: 1px solid $gray200;
	padding: 0px 1rem 0.75rem 1rem;
	position: relative;

	.errorWrapper {
		position: absolute;
		left: 48%;
		top: 50%;
		transform: translate(-50%, -50%);

		.error {
			padding: 11px 12px;
			background-color: $error300;
			color: white;
			border-radius: 8px;
		}
	}
}
