$radioCircleBorder: var(--radioCircleBorder, 1px solid #d0d5dd);
$radioCircleActiveBorder: var(--radioCircleActiveBorder, 1px solid #5552EE);
$radioCircleActiveHoverBorder: var(--radioCircleActiveHoverBorder, 1px solid #5552EE);
$radioCircleBorderDisabled: var(--radioCircleBorderDisabled, 1px solid #d0d5dd);
$radioCircleBorderDisabledHover: var(--radioCircleBorderDisabled, 1px solid #9ea0a3);
$radioCircleColorDisabled: var(--radioCircleColorDisabled, #d0d5dd);
$radioHoverBackgroundColor: var(--radioHoverBackgroundColor, #F9FAFB);
$radioActiveInnerCircleBackgroundColor: var(--radioActiveInnerCircleBackgroundColor, #5552EE);






