@import '@monorepo/base/src/styles/devices';
@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import './dropdown.default.scss';

@mixin text-overflow {
	white-space: pre;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdownAndError {
	width: 100%;
	height: 100%;
	max-width: $dropdownWrapperMaxWidth;
	max-height: $dropdownWrapperMaxHeight;
}

.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	transition: all 0.5s ease;
	text-align: left;
	position: relative;
	background-color: $dropdownWrapperBackgroundColor;
	border-radius: $dropdownWrapperBorderRadius;
	border: $dropdownWrapperBorder;
	font-size: $dropdownWrapperFontSize;
	color: $dropdownWrapperColor;
	white-space: pre;
	min-width: 120px;
	margin: $dropdownWrapperMargin;

	&.dropdownError {
		border: $dropdownErrorBorder;
	}

	&.active {
		border: $dropdownActiveBorder;
		box-shadow: $dropdownActiveBoxShadow;

		.label {
			opacity: $dropdownActiveLabelOpacity;
		}
	}

	&.disabled {
		background-color: $dropdownWrapperDisabledBackgroundColor;
		border: $dropdownWrapperDisabledBorder;

		.label {
			color: $dropdownDisabledLabelColor;
		}

		.dropdown {
			cursor: not-allowed;
			opacity: $dropdownDisabledOpacity;

			.dropdownBar {
				color: $dropdownDropdownBarDisabledColor;
			}
		}
	}

	.label {
		position: absolute;
		box-sizing: border-box;
		transition: opacity 0.2s ease-in-out;
		outline: none;
		top: $dropdownLabelFromTop;
		left: $dropdrownLabelFromLeft;
		font-size: $dropdownLabelFontSize;
		background-color: $dropdownLabelBackgroundColor;
		padding: $dropdownLabelPadding;
		color: $dropdownLabelColor;
		opacity: $dropdownLabelOpacity;
	}

	.searchbar {
		outline: none;
		width: 100%;
		margin-top: $dropdownSearchbarMargin;
		padding: $dropdownSearchbarPadding;
		background-color: $dropdownOptionsBackgroundColor;
		position: sticky;
		top: 0;
	}

	.dropdown {
		cursor: pointer;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: $dropdownPadding;
		border-radius: $dropdownWrapperBorderRadius;

		.arrowIcon {
			color: $dropdownArrowIcon;
		}

		&.focused {
			border: $dropdownFocusBorder;
			box-shadow: $dropdownFocusBoxShadow;
		}

		.dropdownBar {
			display: flex;
			gap: 6px;
			@include text-overflow;

			.activeOption {
				padding: 5px;
				border: 1px solid #d0d5dd;
				color: #344054;
				font-weight: 500;
				border-radius: 6px;
				font-size: 14px;
				display: flex;
				align-items: center;

				&.disabled {
					:first-child {
						cursor: not-allowed;
						color: $dropdownOptionActiveDisabledColor;
					}

					span:nth-child(2) {
						color: $dropdownOptionActiveArrowDisabledColor !important;
						cursor: not-allowed;
					}
				}
			}
		}

		.closeIcon {
			margin-left: auto;
		}
	}

	.options {
		position: absolute;
		width: 100%;
		border-top: none;
		overflow: hidden;
		visibility: hidden;
		z-index: 9;
		list-style: none;
		transition: all 0.2s ease-in-out;
		transform-origin: top;
		background-color: $dropdownOptionsBackgroundColor;
		left: $dropdownOptionsFromLeft;
		margin: $dropdownOptionsMargin;
		border: $dropdownOptionsBorder;
		border-radius: $dropdownOptionsBorderRadius;
		padding: $dropdownOptionsPadding;
		animation: opacityIn 0.2s ease-in-out;
		display: flex;
		flex-direction: column;

		&.extraHeight {
			max-height: $dropdownExtraHeight;
		}

		&.activeOptions {
			visibility: visible;
			opacity: 1;
		}

		&.fromBottom {
			top: $dropdownOptionsFromTop;
		}

		&.fromTop {
			bottom: calc(100% + 5px) !important;
			max-height: 300px;
		}

		.listOptions {
			overflow-y: auto;
			max-height: $dropdownOptionsMaxHeight;

			li {
				padding: $dropdownOptionPadding;
				margin: $dropdownOptionMargin;
				cursor: pointer;

				&:hover {
					background-color: $dropdownOptionHoverBackgroundColor;
				}

				&:active {
					background-color: $dropdownOptionActiveBackgroundColor;
				}

				&.highlight {
					background-color: $dropdownOptionHighlightBackgroundColor;
				}

				&.currentNavigate {
					background-color: $dropdownOptionHoverBackgroundColor;
				}

				.multiOption {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}

		.separatorLine {
			margin: $dropdownSeperatorLineMargin;
		}

		.skeleton {
			padding: 10px;
		}

		.actions {
			position: sticky;
			border-top: 1px dashed #d0d5dd;
			bottom: 0px;
			background-color: white;
		}
	}
}
