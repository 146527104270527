.grid {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 72px;

	.donutSize {
		width: 261px;
		position: relative;
	}
}

.error {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 11px 12px;
	background-color: #fda29b;
	color: white;
	border-radius: 8px;
	width: 261px;
}

.noResults {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 11px 12px;
	background-color: #adaeff;
	text-align: center;
	color: white;
	border-radius: 8px;
	width: 261px;
}
