@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import 'src/styles/theme';
@import './table.default.scss';

.wrapper {
	width: 100%;

	&.withSelections {
		.tableWrapper {
			.tableResponsive {
				max-height: $tableTableResponsiveMaxHeight;

				.table {
					.head {
						.header {
							&:first-child {
								padding: $tableThFirstChildPadding;
							}
						}
					}

					.body {
						.row {
							.cell {
								&:first-child {
									padding: $tableTdFirstChildPadding;
								}
							}
						}
					}
				}
			}
		}
	}

	.tableWrapper {
		background-color: $tableWrapperBGColor;
		border-radius: $tableBorderRaduis;
		border: $tableTableWrapperBorder;

		.tableResponsive {
			display: block;
			overflow-x: auto;
			width: 100%;
			-webkit-overflow-scrolling: touch;
			position: relative;
			border-radius: $tableBorderRaduis;

			.barLoader {
				position: absolute;
				top: $tableBarLoaderFromTop;
				left: $tableBarLoaderFromLeft;
				width: 100%;
				height: $tableBarLoaderHeight;
			}

			.table {
				width: 100%;
				background-color: $tableBGColor;
				border-spacing: 0px;
				border-collapse: separate;

				.head {
					color: $tableHeaderTextColor;
					background-color: $tableHeaderBGColor;
					text-align: left;
					position: sticky;
					top: $tableTHeadFromTop;
					z-index: 1;
					width: 100%;

					&.disabled {
						color: $tableHeaderColorDisabled;
						background-color: red;
					}

					.row {
						// display: flex;
						// width: 100%;

						.header {
							// border-top: 1px solid $tableBorderColor;
							border-right: $tableTableThBorderRight;
							border-bottom: $tableTableThBorderBottom;
							font-weight: $tableTableThFontWeight;
							top: $tableTableThFromTop;
							z-index: 1;
							background-color: $tableTableThBackgroundColor;
							font-size: $tableThFontSize;
							padding: $tableHeaderPadding;
							cursor: pointer;

							.thContent {
								display: flex;
								align-items: center;
								white-space: pre;

								.thIcon {
									display: flex;
									position: relative;
									align-items: center;
									left: $tableTableThIconFromLeft;
									bottom: $tableTableThIconFromBottom;
								}
							}
						}
					}
				}

				.body {
					padding: $tableTableTBodyPadding;

					.row {
						// display: flex;
						// width: 100%;

						&.summary {
							background-color: $tableBGColor;
							position: sticky;
							top: $tableSummaryFromTop;
							z-index: 1;

							.cell {
								&:first-child {
									padding: 0;
									// border-bottom: 1px dashed red;
								}
							}
						}

						&:last-child {
							.cell {
								border-bottom: none;
							}
						}

						:global(.tableQuickActions) {
							visibility: hidden;

							&:global(.forceShow) {
								visibility: visible;
							}
						}

						// :global(.tableLink) {}

						:global(.tableLinkEditIcon) {
							position: relative;
							top: -1px;
							visibility: hidden;
						}

						&.selectedRow {
							background-color: $tableBodyRowSelectedColor;
							transition: background-color 0.2s ease-in-out;

							&:hover {
								background-color: $tableBodyRowSelectedColor !important;
							}
						}

						&.subRow {
							.cell {
								border-bottom: none;
							}

							+ .nsRow {
								.cell {
									border-top: $tableSubRowTdBorderTop;
								}
							}

							&:last-child {
								.cell {
									border-bottom: $tableSubRowTdLastChildBorderBottom;
								}
							}
						}

						&:hover {
							background-color: $tableTableTrBg;
							transition: 0.15s ease-in-out;

							// :global(.tableLink) {}

							:global(.tableLinkEditIcon) {
								visibility: visible;
							}

							:global(.tableQuickActions) {
								visibility: visible;
							}
						}

						.cell {
							border-right: $tableTableTdBorderRight;
							border-bottom: $tableTableTdBorderBottom;
							padding: $tablePadding;
							color: $tableBodyTextColor;
							font-weight: $tableBodyTextFontWeight;
							font-size: $tableFontSize;
							// white-space: pre;
							// overflow-wrap: break-word;
							&.summaryOverall {
								white-space: pre;
							}
						}

						height: $tableTableTrHeight;

						&.disabled {
							&:hover {
								background-color: white;
							}
						}
					}
				}
			}

			.noRows {
				position: absolute;
				left: 48%;
				transform: translate(-50%, -50%);
				top: 55%;
				background-color: $tableNoRowsBackgroundColor;
				border-radius: $tableNoRowsBorderRadius;
				padding: $tablePadding;
				color: $tableNoRowsColor;
				animation: opacityIn 0.2s ease-in-out;

				&.error {
					background-color: #fda29b;
				}
			}
		}
	}

	.belowTable {
		// border-top: $tableTableTdBorderBottom;
		padding: $tableBelowTablePadding;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.absoluteBorder {
	border-bottom: 1px dashed #98a2b3;
	height: 1px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
}

.arrowDirection {
	font-size: 17px !important;
	color: #7f56d9;
}
