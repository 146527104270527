@import './sidebar.default.scss';

.button {
	display: flex !important;
	width: 18px !important;
	align-items: flex-start !important;
	flex-direction: column;
	cursor: pointer;

	> div {
		transition: width 0.3s ease;
		width: 100%;
		min-width: 18px;
		background-color: $sidebarBurger;
		height: 2px;
		margin-bottom: 3px;
	}
}
